import React from 'react'
import './App.css'
import RouterComponent from './views/router/Router'
import { Provider as ProviderRedux } from 'react-redux'
import Store from './redux/Store'
import CookiesBar from './components/CookiesBar'
import './locales'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme()
function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <ProviderRedux store={Store}>
        <ThemeProvider theme={theme}>
          <RouterComponent />
          <CookiesBar />
          <ToastContainer autoClose={3000} />
        </ThemeProvider>
      </ProviderRedux>
    </QueryClientProvider>
  )
}

export default App
