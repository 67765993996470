const initialState = {
  user: {},
  acolhimentoUnread: 0
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        user: action.payload
      }
    case 'EMPTY_USER':
      return {
        ...state,
        user: {}
      }
    case 'INCREMENT_ACOLHIMENTO_UNREAD':
      return {
        ...state,
        acolhimentoUnread: state.acolhimentoUnread + 1
      }
    case 'RESET_ACOLHIMENTO_UNREAD':
      return {
        ...state,
        acolhimentoUnread: 0
      }
    default:
      return state
  }
}

export default UserReducer
