export const addUser = item => dispatch => {
  dispatch({
    type: 'ADD_USER',
    payload: item
  })
}

export const emptyUser = () => dispatch => {
  dispatch({
    type: 'EMPTY_USER'
  })
}

export const incrementAcolhimentoUnread = () => dispatch => {
  dispatch({
    type: 'INCREMENT_ACOLHIMENTO_UNREAD'
  })
}

export const resetAcolhimentoUnread = () => dispatch => {
  dispatch({
    type: 'RESET_ACOLHIMENTO_UNREAD'
  })
}
