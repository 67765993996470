/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import ApiNoblier from '../services/aAPiNoblier'
import { getUrlBase } from '../utils/functions'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { incrementAcolhimentoUnread } from '../redux/actions/UserAction'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import RestaurantIcon from '@mui/icons-material/Restaurant'
import EmergencyOutlinedIcon from '@mui/icons-material/EmergencyOutlined'
const typesMock = [
  {
    valor: 'ACOLHIMENTO_ATIVO_ANIVERSARIO',
    nome: 'Acolhimento Aniversário',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO_NUTRICIONAL',
    nome: 'Acolhimento Nutricional',
    iconName: 'NutritionIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO_RESULTADO',
    nome: 'Acolhimento Resultado',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'DENUNCIA',
    nome: 'Denúncia',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'RH',
    nome: 'Fale com o RH',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'NUTRI',
    nome: 'Momento Nutri',
    iconName: 'NutritionIcon'
  },
  {
    valor: 'PEDIDO_AJUDA',
    nome: 'Pedido de ajuda',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO',
    nome: 'Suporte',
    iconName: 'PsychologyIcon'
  }
]

const iconMap = {
  NutritionIcon: <RestaurantIcon />,
  PsychologyIcon: <EmergencyOutlinedIcon />
}

function SocketToast({ userReducer }) {
  const toastMap = useRef({})
  const activeToasts = useRef(new Set())
  const history = useHistory()
  const dispatch = useDispatch()
  const expireTime = Infinity

  // Função para navegar para o chat
  const navigateToChat = (chat, isAdmin) => {
    const path = isAdmin ? 'admin' : 'admin-company'
    history.push(`/${path}/company-chat-request/${chat}`)
  }

  useEffect(() => {
    if (!userReducer || !userReducer.user) return

    const isAdmin = userReducer.user.isAdmin

    const client = io(getUrlBase(true), {
      extraHeaders: {
        authorization: `Bearer ${ApiNoblier?.token()}`
      }
    })

    client.emit('enterChat', { chat: userReducer.user.empresa })

    const handleReceivedNotification = data => {
      const { chat_id, sender, pedido_ajuda, tipo } = data
      console.log('[🔔 Notificação recebida]', data)

      const tipoFormatado = (tipo || '').toUpperCase()
      console.log('[🔍 Tipo formatado]', tipoFormatado)

      const tiposAcolhimento = [
        'ACOLHIMENTO_ATIVO',
        'ACOLHIMENTO_ATIVO_ANIVERSARIO',
        'ACOLHIMENTO_ATIVO_NUTRICIONAL',
        'ACOLHIMENTO_ATIVO_RESULTADO',
        'PEDIDO_AJUDA',
        'NUTRI'
      ]

      if (
        tiposAcolhimento.includes(tipoFormatado) &&
        !history.location.pathname.includes(
          '/admin/company-requests-for-help-v2'
        )
      ) {
        console.log(
          '[📥 Dispatch] incrementAcolhimentoUnread por usuário único'
        )
        if (!toastMap.current[sender]) {
          toastMap.current[sender] = true
          dispatch(incrementAcolhimentoUnread())
        }
      }

      const chatType = typesMock.find(item => item.valor === tipo)

      const currentPath = history.location.pathname
      const currentSearch = history.location.search

      if (currentPath.includes(chat_id) || currentSearch.includes(chat_id)) {
        return
      }

      if (sender !== 'admin') {
        showToast(chat_id, sender, isAdmin, chatType)
      }
    }

    client.on('receivedNotification', handleReceivedNotification)

    const showToast = (chat_id, sender, isAdmin, chatType) => {
      // Incrementa o contador ou inicializa com 1
      toastMap.current[chat_id] = (toastMap.current[chat_id] || 0) + 1

      // Seleciona o ícone baseado no chatType
      const icon = chatType ? iconMap[chatType.iconName] : null

      // Cria ou atualiza o toast usando toast.custom com o mesmo id
      toast.custom(
        t => (
          <ToastBar toast={t}>
            {({ icon: defaultIcon }) =>
              t.visible && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 280,
                      p: 2,
                      borderRadius: 2,
                      boxShadow: 3,
                      bgcolor: 'background.paper',
                      borderLeft: '4px solid #1976d2',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      navigateToChat(chat_id, isAdmin)
                      delete toastMap.current[chat_id]
                      toast.remove(`toast-${chat_id}`)
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      {icon || defaultIcon}
                      <Typography
                        variant="subtitle2"
                        sx={{ ml: 1, fontWeight: 'bold' }}
                      >
                        {chatType?.nome || 'Nova mensagem'}
                      </Typography>
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                      <strong>{sender}</strong> enviou uma mensagem
                      {toastMap.current[chat_id] > 1
                        ? ` (${toastMap.current[chat_id]})`
                        : ''}
                    </Typography>

                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        delete toastMap.current[chat_id]
                        toast.remove(`toast-${chat_id}`)
                      }}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              )
            }
          </ToastBar>
        ),
        {
          id: `toast-${chat_id}`,
          duration: 7000,
          onOpen: () => {
            activeToasts.current.add(chat_id)
          },
          onClose: () => {
            delete toastMap.current[chat_id]
            activeToasts.current.delete(chat_id)
          }
        }
      )
    }

    // Cleanup ao desmontar o componente
    return () => {
      client.off('receivedNotification', handleReceivedNotification)
      client.disconnect()
    }
  }, [userReducer, history])

  return (
    <Toaster
      position="bottom-right"
      gutter={8}
      containerStyle={{ marginBottom: '10px' }}
      toastOptions={{
        style: {
          boxShadow: 'none',
          padding: 0,
          background: 'transparent'
        }
      }}
    />
  )
}

// Mapeia o estado do Redux
const mapStateToProps = state => ({
  userReducer: state.userStore.user
})

export default connect(mapStateToProps)(SocketToast)
